import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class SessionStorageService {
  private sessionStorage: any;

  constructor() {
    this.sessionStorage = window.sessionStorage;
  }

  get(key: string) {
    const item = this.sessionStorage.getItem(key);
    return item !== null && item !== "undefined"
      ? JSON.parse(this.sessionStorage.getItem(key))
      : null;
  }

  set(key: string, value: any) {
    this.sessionStorage.setItem(key, JSON.stringify(value));
  }

  delete(key: string) {
    this.sessionStorage.removeItem(key);
  }

  deleteAll() {
    this.sessionStorage.clear();
  }
}
