import { Injectable } from "@angular/core";
import { collection } from "firebase/firestore";
import { signInWithCustomToken, signOut } from "firebase/auth";
import { onSnapshot } from "firebase/firestore";
import { auth as firebaseAuth, firestore } from "src/app/firebase";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  numberOfNotifications = 0;
  forceLogout = new BehaviorSubject<boolean>(false);
  unsubscribeFromFirestoreNotification!: () => void;

  // #region main actions

  logInFireStore(firebaseToken: string, userId: string, userRole: string) {
    if (!firebaseAuth || !firebaseToken) {
      return;
    }
    signInWithCustomToken(firebaseAuth, firebaseToken).then(res => res, err => err);
    this.unsubscribeFromFirestoreNotification = onSnapshot(
      collection(firestore, "app-notifications", userId, `${userRole}-notifications`),
      snapshot => {
        const messages = snapshot.docs.map(doc => doc.data());
        this.numberOfNotifications = messages[0]?.count || 0;
      },
    );
  }

  logOutFireStore() {
    signOut(firebaseAuth);
    if (this.unsubscribeFromFirestoreNotification) {
      this.unsubscribeFromFirestoreNotification();
    }
  }

  // #endRegion
}
