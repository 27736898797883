import { Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { RoleGuard } from "./core/guards/role.guard";
import { EUSerRole } from "./core/enums/user-roles.enum";
import { LoginGuard } from "./core/guards/login.guard";

export const APP_ROUTES: Routes = [
  {
    path: "",
    redirectTo: "authentication/login",
    pathMatch: "full",
  },
  {
    path: "loading",
    loadComponent: () =>
      import("./components/loading/loading.component").then(m => m.LoadingComponent),
  },
  {
    path: "privacy-policy",
    loadComponent: () =>
      import("./pages/privacy-policy/privacy-policy.component").then(m => m.PrivacyPolicyComponent),
  },
  {
    path: "privacy-policy-ar",
    loadComponent: () =>
      import("./pages/privacy-policy-ar/privacy-policy-ar.component").then(
        m => m.PrivacyPolicyArComponent,
      ),
  },
  {
    path: "authentication",
    loadChildren: () =>
      import("./core/routes/authentication.routes").then(m => m.AUTHENTICATION_ROUTES),
    canActivate: [LoginGuard],
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    loadChildren: () => import("./core/routes/dashboard.routes").then(m => m.DASHBOARD_ROUTES),
  },
  {
    path: "setup-stepper",
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [EUSerRole.Admin] },
    loadComponent: () =>
      import("./pages/company-setup/setup-stepper/setup-stepper.component").then(
        m => m.SetupStepperComponent,
      ),
  },
  {
    path: "**",
    loadComponent: () =>
      import("./pages/not-found/not-found.component").then(m => m.NotFoundComponent),
  },
];
