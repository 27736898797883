import { Component, OnInit, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterOutlet } from "@angular/router";
import { SharedModule } from "./core/modules/shared.module";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { COOKIES_KEYS } from "./core/constants/storage-keys";
import { DEFAULT_PEOPLE_MATE_LANGUAGE, LANGUAGES_OPTIONS } from "./core/constants/languages";
import { Language } from "./core/interfaces/language.interface";
import { CookiesService } from "./core/services/cookies.service";
import { LoadingComponent } from "./components/loading/loading.component";
import { LoaderService } from "./core/services/loader.service";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-root",
  standalone: true,
  imports: [SharedModule, CommonModule, RouterOutlet, LoadingComponent],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // #region declare & init. variables
  langClass: string;
  languages: string[] = LANGUAGES_OPTIONS;
  selectedLang: Language = DEFAULT_PEOPLE_MATE_LANGUAGE;

  loaderService = inject(LoaderService);
  // #endregion

  // #region constructor

  constructor(
    private translateService: TranslateService,
    private cookiesService: CookiesService,
    private titleService: Title,
  ) {
    this.handleSettingDefaultLanguage();
    this.langClass = this.translateService.currentLang;

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langClass = event.lang;
    });
    this.setTitle();
  }

  public setTitle() {
    const title = environment.TITLE;
    const favIcon = document.querySelector("#favIcon");
    (favIcon as HTMLLinkElement).href = environment.ICON;
    this.titleService.setTitle(title);
  }

  handleSettingDefaultLanguage() {
    this.translateService.addLangs(this.languages);

    if (this.cookiesService.check(COOKIES_KEYS.LANGUAGE_KEY)) {
      this.selectedLang = this.cookiesService.get(COOKIES_KEYS.LANGUAGE_KEY);
    } else {
      this.cookiesService.set(COOKIES_KEYS.LANGUAGE_KEY, this.selectedLang);
    }
    this.translateService.use(this.selectedLang.code);
  }

  // #endregion

  // #region ngOnInit

  moveScrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.appStoreService.setLanguage(event.lang);
      // if (event.lang == "ar") {
      //   this.textDir = "rtl";
      // } else {
      //   this.textDir = "ltr";
      // }
    });
  }

  // #endregion
}
