import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { NgxPaginationModule } from "ngx-pagination";
import { ClickStopPropagation } from "../directive/click-stop-propagation";

@NgModule({
  imports: [MatIconModule, TranslateModule, NgxPaginationModule, ClickStopPropagation],
  exports: [MatIconModule, TranslateModule, ClickStopPropagation],
})
export class SharedModule { }
