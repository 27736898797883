import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CompanyDetails } from "src/app/core/interfaces/company.interface";
import { ValidationError } from "src/app/core/interfaces/validation-error.interface";

@Injectable({
  providedIn: "root",
})
export class ObservableService {
  // #region List of Enterprises

  private companies = new BehaviorSubject<CompanyDetails[]>([]);

  setCompanyList(data: CompanyDetails[]) {
    this.companies.next(data);
  }

  getCompanyList(): Observable<CompanyDetails[]> {
    return this.companies.asObservable();
  }

  // #endregion

  // #region Form Validation Errors

  private validation_errors = new BehaviorSubject<ValidationError>({});

  setValidationErrors(errors: ValidationError) {
    this.validation_errors.next(errors);
  }

  getValidationErrors() {
    return this.validation_errors.asObservable();
  }

  // #endregion
}
