export const AUTH_API = {
  LOGIN: "users/login",
  LOGOUT: "users/logout",
  FORGET_PASSWORD: "users/reset-password/send-email",
  VALIDATE_OTP: (user: number) => `users/${user}/validate-otp`,
  RESET_PASSWORD: "users/reset-password",
  REFRESH_ACCESS_TOKEN: "users/refresh-tokens",
  ACTIVATE_USER_COMPANY: "users/activate-company/$company",
  USER_SWITCH_ROLE: "users/switch-role",
  UPDATE_USER: (user: number) => `users/${user}/update`,
  USER_PERMISSION: "users/my-permissions",
  USER_GROUPS: "users/my-groups",
};
