import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { ObservableService } from "src/app/shared/service/observable.service";

@Injectable({ providedIn: "root" })
export class ApiInterceptor implements HttpInterceptor {
  constructor(private observableService: ObservableService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !req.url.startsWith("http") &&
      !req.url.startsWith("https") &&
      !req.url.includes("assets")
    ) {
      const BASE_URL = environment.BASE_URL;
      const apiReq = req.clone({ url: `${BASE_URL}${req.url}` });
      return next.handle(apiReq).pipe(
        tap(() => {
          this.observableService.setValidationErrors({});
        }),
      );
    } else {
      return next.handle(req).pipe(
        tap(() => {
          this.observableService.setValidationErrors({});
        }),
      );
    }
  }
}
