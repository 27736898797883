import { ValidateOTPResponse } from "src/app/pages/authentication/user-otp/user-otp.component";
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, catchError, switchMap, throwError } from "rxjs";
import { AuthService } from "../services/auth.service";
import { AUTH_API } from "../apis/auth.api";
import { DEFAULT_PEOPLE_MATE_LANGUAGE } from "../constants/languages";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
  ) {}

  getUserLanguage(): string {
    this.translateService.currentLang;
    return this.translateService.currentLang || DEFAULT_PEOPLE_MATE_LANGUAGE.code;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.UserToken;
    const userId = this.authService.User?.id || 0;
    if (
      !request.url.startsWith("http") &&
      !request.url.startsWith("https") &&
      !request.url.includes("assets") &&
      !request.url.includes(AUTH_API.LOGIN) &&
      !request.url.includes(AUTH_API.VALIDATE_OTP(userId)) &&
      !request.url.includes(AUTH_API.REFRESH_ACCESS_TOKEN) &&
      token
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": this.getUserLanguage(),
        },
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 && token && this.authService.User) {
          return this.authService.refreshToken().pipe(
            switchMap((response: { access: string; refresh: string }) => {
              this.authService.User = {
                ...this.authService.User,
                access: response.access,
                refresh: response.refresh,
              } as ValidateOTPResponse;
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${response.access}`,
                  "Accept-Language": this.getUserLanguage(),
                },
              });
              return next.handle(request);
            }),
            catchError(refreshError => {
              return throwError(refreshError);
            }),
          );
        } else {
          return throwError(error);
        }
      }),
    );
  }
}
