export enum EServices {
  Leave = 1,
  Permission = 2,
  WorkFromHome = 3,
}

export enum EmployeeServiceType {
  Entity = "entity",
  Request = "request",
}
