export const STORAGE_KEYS = {
  USER_DATA_KEY: "people-mate-user-data",
  USER_MODULES_KEY: "people-mate-user-modules",
  USER_ROLES_KEY: "people-mate-user-roles",
  USER_REMEMBER_ME: "people-mate-user-remember-me",
  USER_PERMISSIONS: "people-mate-user-permissions",
  ACTIVE_COMPANY: "people-mate-active-company",
  EMPLOYEE_REQUEST_SELECTED_TAB: "employee-request-selected-tab",
};
export const COOKIES_KEYS = {
  LANGUAGE_KEY: "PEOPLE_MATE_LANGUAGE",
};
