export const DASHBOARD_ROUTES_URL = {
  Login: "/authentication/login",
  HOME: "/dashboard/home",
  NOTIFICATIONS: "/dashboard/notification",

  // #region company setup

  // COMPANY
  COMPANY_LIST: "/dashboard/company-setup/company/list",

  // branch
  BRANCH_LIST: "/dashboard/company-setup/branch/list",

  // #region structure
  DEPARTMENT_LIST: "/dashboard/company-setup/structure/department/list",
  JOB_LIST: "/dashboard/company-setup/structure/job/list",
  GRADE_LIST: "/dashboard/company-setup/structure/grade/list",
  POSITION_LIST: "/dashboard/company-setup/structure/position/list",
  // #endregion

  // user management
  USER_MANAGEMENT_LIST: "/dashboard/user-management/list",

  // #endregion

  // #region system configuration

  PERSONNEL_SETUP_LIST: "/dashboard/system-configuration/people-central/personnel/list",
  SELF_SERVICE_SETUP_LIST: "/dashboard/system-configuration/people-central/self-services/list",

  LEAVES_SETUP_LIST: "/dashboard/system-configuration/leave/list",
  PERMISSIONS_SETUP_LIST: "/dashboard/system-configuration/permission/list",

  LEAVE_PLANS_LIST: "/dashboard/system-configuration/leave-plans/list",
  EMPLOYEE_DATA: "/dashboard/system-configuration/employee-data",

  WORKFLOW_STRUCTURE_SETUP_LIST: "/dashboard/system-configuration/workflow-structure/list",
  WORKFLOW_STRUCTURE_SETUP_CREATE: "/dashboard/system-configuration/workflow-structure/add",
  WORKFLOW_STRUCTURE_SETUP_UPDATE: "/dashboard/system-configuration/workflow-structure/update/",
  WORKFLOW_STRUCTURE_SETUP_VIEW: "/dashboard/system-configuration/workflow-structure/view/",

  // #endregion

  // #region HR assistant
  HR_ASSISTANT_SETUP: "/dashboard/hr-assistant/setup",
  HR_ASSISTANT_CHAT: "/dashboard/hr-assistant/chat",

  // #endregion

  // #region people central

  EMPLOYEE_LIST: "/dashboard/people-central/employee/list",
  CREATE_EMPLOYEE: "/dashboard/people-central/employee/add",

  // #endregion

  // #region employee services
  LEAVES_LIST: "/dashboard/employee-services/leaves/list",
  LEAVES_BALANCE: "/dashboard/employee-services/leaves/balance",
  PERMISSIONS_LIST: "/dashboard/employee-services/permissions/list",
  WORK_ON_VACATION_LIST: "/dashboard/employee-services/work-on-vacation/list",
  BUSINESS_MISSION_LIST: "/dashboard/employee-services/business-mission/list",
  WORKING_FROM_HOME_LIST: "/dashboard/employee-services/working-from-home/list",
  // #endregion

  // #region attendance management

  // policies
  YEARLY_HOLIDAY: "/dashboard/attendance-management/policy/yearly-holidays",
  WORK_SCHEDULE: "/dashboard/attendance-management/policy/work-schedule/list",
  WORKING_POLICY: "/dashboard/attendance-management/policy/company-policy/list",
  WORKING_POLICY_DEDUCTION_EXCEPTION:
    "/dashboard/attendance-management/policy/deduction-exceptions/list",
  LEAVE_TYPE: "/dashboard/attendance-management/policy/leave-type/list",

  // rotations
  ROTATION_GROUP: "/dashboard/attendance-management/rotation/group/list",
  ROTATION_GROUP_CREATE: "/dashboard/attendance-management/rotation/group/create",
  ROTATION_GROUP_UPDATE: "/dashboard/attendance-management/rotation/group/update/",
  ROTATION_GROUP_VIEW: "/dashboard/attendance-management/rotation/group/view/",
  ELIGIBILITY: "/dashboard/attendance-management/rotation/eligibility/list",

  // logs
  ATTENDANCE: "/dashboard/attendance-management/log/attendance/list",
  SUBORDINATES_ATTENDANCE: "/dashboard/attendance-management/log/subordinates-attendance",

  // #endregion

  // #region payroll setup

  ADD_SALARY_STRUCTURE: "/dashboard/payroll-setup/salary-structure/details",
  ASSIGN_SALARY_STRUCTURE: "/dashboard/payroll-setup/salary-structure/assign",
  SALARY_STRUCTURE_LIST: "/dashboard/payroll-setup/salary-structure/list",
  PAYROLL_ELEMENT_LIST: "/dashboard/payroll-setup/element/list",
  ELEMENT_BATCH_LIST: "/dashboard/payroll-setup/element-batch/list",
  EMPLOYEE_BATCH_LIST: "/dashboard/payroll-setup/employee-batch/list",
  BANK_LIST: "/dashboard/payroll-setup/bank/list",
  TAX_RULES_LIST: "/dashboard/payroll-setup/tax-rules/list",
  PAYROLL_DEFINITION_LIST: "/dashboard/payroll-setup/payroll-definition/list",
  EXCHANGE_RATE_LIST: "/dashboard/payroll-setup/exchange-rate/list",
  SOCIAL_INSURANCE_LIST: "/dashboard/payroll-setup/social-insurance/list",

  // #endregion
};
