import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDYVY4qAo1AedJ4qlUcGA6By8FBO53sa2A",
  authDomain: "people-mate-notifications-dev.firebaseapp.com",
  projectId: "people-mate-notifications-dev",
  storageBucket: "people-mate-notifications-dev.appspot.com",
  messagingSenderId: "424351932928",
  appId: "1:424351932928:web:18149d6d0bd8631daa6ee5",
  measurementId: "G-VPPKGVZ55T",
  // old creditionals
  // apiKey: "AIzaSyCuEXW1Lf-YkUzbykQbv2PVNq2v72Hup20",
  // authDomain: "peopele-mate-notification.firebaseapp.com",
  // projectId: "peopele-mate-notification",
  // storageBucket: "peopele-mate-notification.appspot.com",
  // messagingSenderId: "1054586195852",
  // appId: "1:1054586195852:web:5d0c4c94c211fec53410a9",
};

const app = initializeApp(firebaseConfig, "peopele-mate-notification");
export const firestore = getFirestore(app);
export const auth = getAuth(app);
