import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "../services/auth.service";
import { DASHBOARD_ROUTES_URL } from "../constants/dashboard-routes-url";

export const LoginGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): CanActivateFn => {
  let canActivate = true;
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.UserToken && !authService.User?.force_reset_password) {
    router.navigate([DASHBOARD_ROUTES_URL.HOME]);
    canActivate = false;
  } else if (!authService.UserToken && state.url !== DASHBOARD_ROUTES_URL.Login) {
    canActivate = false;
    authService.logout();
  }
  return () => {
    return canActivate;
  };
};
