import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";

import { APP_ROUTES } from "./app.routes";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { LocalStorageService } from "./core/services/local-storage.service";
import { SessionStorageService } from "./core/services/session-storage.service";
import { AuthService } from "./core/services/auth.service";

import { InternetInterceptor } from "./core/interceptors/internet.interceptor";
import { LoaderInterceptor } from "./core/interceptors/loader.interceptor";
import { ErrorInterceptor } from "./core/interceptors/error.interceptor";
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { ApiInterceptor } from "./core/interceptors/api.interceptor";
import { ObservableService } from "./shared/service/observable.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";

export function createTranslateLoader(http: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [{ prefix: environment.ASSET, suffix: ".json" }]);
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(MatSnackBarModule),
    LocalStorageService,
    SessionStorageService,
    AuthService,
    ObservableService,
    { provide: HTTP_INTERCEPTORS, useClass: InternetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      MatDatepickerModule,
      MatNativeDateModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpBackend],
        },
      }),
    ),
    provideRouter(APP_ROUTES, withComponentInputBinding()),
    importProvidersFrom([BrowserAnimationsModule]),
    provideAnimations(),
  ],
};
