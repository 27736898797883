import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LocalStorageService {
  private localStorage: any;

  constructor() {
    this.localStorage = window.localStorage;
  }

  get(key: string) {
    const item = this.localStorage.getItem(key);
    return item !== null && item !== "undefined"
      ? JSON.parse(this.localStorage.getItem(key))
      : null;
  }

  set(key: string, value: any) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  delete(key: string) {
    this.localStorage.removeItem(key);
  }

  deleteAll() {
    this.localStorage.clear();
  }
}
