import { SessionStorageService } from "./session-storage.service";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "./local-storage.service";
import { STORAGE_KEYS } from "../constants/storage-keys";
import { ValidateOTPResponse } from "../../pages/authentication/user-otp/user-otp.component";
import { AUTH_API } from "../apis/auth.api";
import { ApiService } from "./api.service";
import { CompanyDetails } from "../interfaces/company.interface";
import { EUSerRole } from "../enums/user-roles.enum";
import { EServices } from "../enums/employee-services.enum";
import { FirebaseService } from "./firebase.service";
import { UserRole, UserModule } from "../interfaces/user-permission";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // #region constructor
  firebase = inject(FirebaseService);
  constructor(
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly sessionStorageService: SessionStorageService,
    private API: ApiService,
  ) {
    // this.logInFireBaseNotifications();
    this.firebase.forceLogout.subscribe((res: boolean) => {
      if (res === true) {
        this.logout(true);
      }
    });
  }

  // #endregion

  // #region actions

  logInFireBaseNotifications() {
    if (this.User) {
      this.firebase.logInFireStore(
        this.User.fire_store_token,
        this.User.id + "",
        this.UserActiveRole.name,
      );
    }
  }

  logout(forced?: boolean) {
    const payload: any = forced ? { forced } : {};
    payload.refresh = this.User?.refresh;
    if (this.User?.refresh)
      this.API.sendDataToServer(AUTH_API.LOGOUT, payload).subscribe(() => {
        this.firebase.logOutFireStore();
        this.clearUserData();
      });
    else this.clearUserData();
  }

  clearUserData() {
    this.localStorageService.deleteAll();
    this.sessionStorageService.deleteAll();

    this.router.navigate(["/"]);
  }

  // #endregion

  // #region local storage actions

  set User(user: ValidateOTPResponse | null) {
    this.userStorage.set(STORAGE_KEYS.USER_DATA_KEY, user);
  }

  get User(): ValidateOTPResponse | null {
    const user = this.userStorage.get(STORAGE_KEYS.USER_DATA_KEY);
    return user && typeof user !== "undefined" ? (user as ValidateOTPResponse) : null;
  }

  set UserToken(token: string | null) {
    if (token) {
      const user: ValidateOTPResponse = this.userStorage.get(STORAGE_KEYS.USER_DATA_KEY);
      user.access = token;
      this.userStorage.set(STORAGE_KEYS.USER_DATA_KEY, user);
    }
  }

  get UserToken() {
    const user = this.userStorage.get(STORAGE_KEYS.USER_DATA_KEY);
    return user && typeof user !== "undefined" ? (user as ValidateOTPResponse).access : null;
  }

  deleteUser() {
    this.userStorage.delete(STORAGE_KEYS.USER_DATA_KEY);
  }

  // TODO: Need to be removed in presence of new user permissions management service
  get UserModulesPermissions() {
    const user_modules = this.userStorage.get(STORAGE_KEYS.USER_MODULES_KEY);
    return user_modules && typeof user_modules !== "undefined"
      ? (user_modules as UserModule[])
      : [];
  }

  // TODO: Need to be removed in presence of new user permissions management service
  set UserModulesPermissions(user_modules: UserModule[] | null) {
    this.userStorage.set(STORAGE_KEYS.USER_MODULES_KEY, user_modules);
  }

  // #endregion

  // #region company second language

  hasRole(allowedRoles: string[]): boolean {
    const hasAccess = allowedRoles.includes(this.UserActiveRole.name);
    return hasAccess;
  }

  get UserActiveRole(): UserRole {
    return (this.UserRoles || []).find(role => role.active) || ({} as UserRole);
  }

  get UserRoles() {
    const user_roles = this.userStorage.get(STORAGE_KEYS.USER_ROLES_KEY);
    return user_roles && typeof user_roles !== "undefined" ? (user_roles as UserRole[]) : [];
  }

  set UserRoles(user_roles: UserRole[] | null) {
    this.userStorage.set(STORAGE_KEYS.USER_ROLES_KEY, user_roles);
  }

  get IsAdmin(): boolean {
    return this.UserActiveRole.name === EUSerRole.Admin;
  }

  get IsEmployee(): boolean {
    return this.UserActiveRole.name === EUSerRole.Employee;
  }

  get IsHr(): boolean {
    return this.UserActiveRole.name === EUSerRole.HR;
  }

  get IsManager(): boolean | undefined {
    return this.User?.is_manager;
  }

  set active_company(value: CompanyDetails) {
    this.userStorage.set(STORAGE_KEYS.ACTIVE_COMPANY, value);
  }

  get active_company() {
    return this.userStorage.get(STORAGE_KEYS.ACTIVE_COMPANY) || ({} as CompanyDetails);
  }

  get has_work_from_home_service(): boolean {
    const company_has_from_home = this.active_company.active_services.includes(
      EServices.WorkFromHome,
    );

    // case Admin
    if (this.IsAdmin && company_has_from_home) return true;
    // case Employee
    else if (this.IsEmployee && company_has_from_home) return true;
    else return false;
  }

  // #endregion

  // #region helpers

  set remember_me(value: boolean) {
    this.localStorageService.set(STORAGE_KEYS.USER_REMEMBER_ME, value);
  }

  get remember_me() {
    return this.localStorageService.get(STORAGE_KEYS.USER_REMEMBER_ME) || null;
  }

  get userStorage(): LocalStorageService | SessionStorageService {
    return this.remember_me ? this.localStorageService : this.sessionStorageService;
  }

  // #endregion

  // #region main actions

  refreshToken() {
    return this.API.sendDataToServer<RefreshToken, RefreshToken>(AUTH_API.REFRESH_ACCESS_TOKEN, {
      access: this.User!.access,
      refresh: this.User!.refresh,
    });
  }

  // #endregion
}

export interface RefreshToken {
  access: string;
  refresh: string;
}

export const UNAUTHENTICATED_RESPONSE_MESSAGES = {
  access_expired: "Given token not valid for any token type",
  refresh_expired: "Invalid access and refresh tokens",
  refresh_blocked: "Invalid or blacklisted access or refresh tokens",
};
