import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginatedListRequest } from "../interfaces/pagination.interface";
import { ResponseInterface } from "../interfaces/response.interface";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private HTTP: HttpClient) {}

  getDataFromServer<TResponse extends object = ResponseInterface>(
    end_point: string,
    pagination?: PaginatedListRequest,
    filter?: object,
  ) {
    return this.HTTP.get<TResponse>(end_point, { params: { ...pagination, ...filter } });
  }

  getFileFromServer<TResponse extends object = ResponseInterface>(
    end_point: string,
    filter?: object,
  ) {
    return this.HTTP.get<TResponse>(end_point, {
      params: { ...filter },
      observe: "body",
      responseType: "blob" as "json",
    });
  }

  getDataFromServerById<TResponse extends object = ResponseInterface>(
    end_point: string,
    id: number,
  ) {
    return this.HTTP.get<TResponse>(end_point + id);
  }

  sendDataToServer<TBody extends object, TResponse extends object = ResponseInterface>(
    end_point: string,
    model: TBody,
    id?: string,
    options?: object,
  ) {
    return this.HTTP.post<TResponse>(id ? end_point + id : end_point, model, options);
  }

  updateDataOnServer<
    TMethod extends "put" | "patch",
    TBody extends object,
    TResponse extends object = ResponseInterface,
  >(method: TMethod, end_point: string, model: TBody, id?: string) {
    return this.HTTP[method]<TResponse>(id ? end_point + id : end_point, model);
  }

  deleteDataOnServer<TResponse extends object = ResponseInterface>(end_point: string, id?: number) {
    return this.HTTP.delete<TResponse>(id ? end_point + id : end_point);
  }
}
